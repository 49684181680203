import React from 'react'
import { Link } from 'gatsby'
import 'styles/components/button.scss'

export enum ButtonColor {
  INDIGO = 'indigo',
  ORANGE = 'orange',
  YELLOW = 'yellow',
  GREEN = 'green',
}

export enum ButtonType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
}

export enum ButtonSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

const Button: React.FunctionComponent<ButtonProps> = ({
  href,
  useLink,
  openNewTab,
  onBtnClick,
  children,
  size = ButtonSize.MEDIUM,
  type = ButtonType.PRIMARY,
  color = ButtonColor.INDIGO,
  className,
}) => {
  const classes = `btn ${size} ${type} ${color} br-small ${className}`

  if (href) {
    return useLink ? (
      <Link className={classes} to={href}>
        {children}
      </Link>
    ) : (
      <a
        className={classes}
        href={href}
        target={openNewTab ? '_blank' : '_self'}
        rel={openNewTab ? 'noopener ”noreferrer' : ''}
      >
        {children}
      </a>
    )
  }

  if (onBtnClick) {
    return (
      <button className={classes} onClick={onBtnClick}>
        {children}
      </button>
    )
  }

  return null
}

interface ButtonProps {
  href?: string
  useLink?: boolean
  openNewTab?: boolean
  onBtnClick?: () => void
  size?: ButtonSize
  color?: ButtonColor
  type?: ButtonType
  className?: string
}

export default Button
