import React from 'react'
import Layout from 'components/layout/layout'
import Button, {
  ButtonColor,
  ButtonType,
  ButtonSize,
} from 'components/button/button'
import 'styles/general.scss'
import 'styles/pages/index.scss'
import BantuLogo from 'images/svgs/bantu-logo.svg'

const IndexPage = () => (
  <Layout>
    <section className="homepage-container">
      <section className="title-section margin-y-6">
        <h1 className="sr-only">Bantu</h1>
        <BantuLogo className="bantu-logo margin-y-4" />
        <h2>Connecting You To Everything Black-Owned</h2>
      </section>
      <section className="signup-container">
        <Button
          href="https://docs.google.com/spreadsheets/d/1HwD6jFHwOA-kBJHvJt7cdfq-TiH9ZQzcpCyRgugmC2A/edit#gid=821814619"
          openNewTab
          className="margin-x-4 text-bold text-uppercase"
          color={ButtonColor.YELLOW}
          type={ButtonType.PRIMARY}
        >
          Find a business
        </Button>
        <Button
          href="https://mailchi.mp/bba6d837013e/bantubizz"
          openNewTab
          className="margin-x-4 text-bold text-uppercase"
          color={ButtonColor.GREEN}
          type={ButtonType.PRIMARY}
        >
          Submit a business!
        </Button>
      </section>
    </section>
  </Layout>
)

export default IndexPage
